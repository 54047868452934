//用户信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
//根据userId获取人才认证信息
const getTalentsIntroduceInfoByUserId = `/gateway/hc-serve/mini/talents/getTalentsIntroduceInfoByUserId`;
//保存或更新人才认证审核表
const saveOrUpdateTalentsIntroduce = `/gateway/hc-serve/mini/talents/saveOrUpdateTalentsIntroduce`;
export {
  userInfoUrl,
  getTalentsIntroduceInfoByUserId,
  saveOrUpdateTalentsIntroduce,
};
